  .btn-margin {
    margin-top: 30px;
  }

  .height-100 {
    height: 90vh;
  }

  .mobile-back {
    background-image: url("./5.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .mobile-back {
      display: none;
    }
  }

  .home-image {
    max-width: 600px;
  }