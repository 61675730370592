body {
  margin: 0;
  font-family: "Work Sans", "League Spartan", "Montserrat", "Open Sans", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-white {
  background-color: #E6DED3;
}

.btn-red {
  background-color: #DE5761;
  color: white;
  padding: 10px 30px;
  border-radius: 0%;
  box-shadow:  5px 5px 10px thistle;
}

.App {
  text-align: center;
  min-height: 100vh;
  scroll-behavior: smooth;
}



/* Style the navigation menu */
.topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    background-color: #E6DED3;
  }
  .height100 {
    height: 80px;
  }

.text-big {
  font-size: x-large;
}
  
  /* Hide the links inside the navigation menu (except for logo/home) */
  .topnav #myLinks {
    transition: 0.3s;
  }
 
  /* Style navigation menu links */
  .topnav a {
    color: #25313C;
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
    /* background-color: #242423; */
  }
  
  /* Style the hamburger menu */
  .topnav a.icon {
    /* background: #242423; */
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  .mt100 {
    margin-top: 80px;
  }
  
  /* Style the active link (or home/logo) */
  .active {
    /* background-color:#242423; */
    color: #25313C;
  }


  .navDiv {
    z-index: 900;
    position: fixed;
    top:0;
    width: 100%;
  }
   
  .menu {
    background-color: #E6DED3;
  }

  @media only screen and (max-width: 600px) { 
    /* Style the navigation menu */
    .topnav {
    color: #25313C;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    padding: 10px;
  }
}

.text-menu {
  text-transform: uppercase;
  color: #686868;
  font-size: 14px;
  font-weight: 600;
}
  .btn-margin {
    margin-top: 30px;
  }

  .height-100 {
    height: 90vh;
  }

  .mobile-back {
    background-image: url(/static/media/5.4d4c9d48.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .mobile-back {
      display: none;
    }
  }

  .home-image {
    max-width: 600px;
  }
.image {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.image-top {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 150px;
}

.image-top-right {
    width: 400px;
    position: absolute;
    top: 0px;
    left: 150px;
}

.image-bottom-right {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 0px;
}

.service-card {
    width: 330px;
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}

.other-service-card {
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}


  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }

.portofolio-gallery {
  height: 350px;
  display: flex;
  overflow: scroll;

  & img {
    margin: 10px; 
  }
}
.primary-button {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
}

.secondary-button {
    background-color: #e2e2e2;
    border-radius: 5px;
    border: #86BDC9;
    padding: 10px 15px;
    color: rgb(41, 41, 41);
    text-decoration: none;
}

.card-div {
    display: flex;
    align-items: stretch;
}

.testimonial-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    width: 400px;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 5px 5px 50px #e2e2e2;
    min-height: 320px;
}

.star-div {
    padding: 10px 0px;
    height: 70px;
}

.star {
    margin: 5px;
    width: 30px;
    height: 30px;
}

.testimonial-text {
    display: flex;
    text-align: justify;
    padding: 0px 10px;
    margin-top: 5px;
    font-size: 14px;
    letter-spacing: 1px;
}

.testimonial-footer {
    display: flex;
    align-self: baseline;
    padding-left: 10px;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: grey;
}

@-webkit-keyframes smallStar{
    0% {
        width: 10px;
        height: 10px;
    }

    100% {
        width: 30px;
        height: 30px;
    }
}

@keyframes smallStar{
    0% {
        width: 10px;
        height: 10px;
    }

    100% {
        width: 30px;
        height: 30px;
    }
}

@-webkit-keyframes smallStar-reverse{
     100% {
        width: 30px;
        height: 30px;
    }
    0% {
        width: 10px;
        height: 10px;
    }
}

@keyframes smallStar-reverse{
     100% {
        width: 30px;
        height: 30px;
    }
    0% {
        width: 10px;
        height: 10px;
    }
}

.star-animation {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}


.star-animation-1 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-2 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 800ms;
            animation-delay: 800ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-3 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 1200ms;
            animation-delay: 1200ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

.star-animation-4 {
    -webkit-animation: smallStar 3s;
            animation: smallStar 3s;
    -webkit-animation-delay: 1600ms;
            animation-delay: 1600ms;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}
.grey-image {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    width: 150px;
}

.scroll { 
    padding: 30px 0px;
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
    justify-content: space-between;
}

.scroll div  {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    -webkit-animation: scroll-horizontal 10s linear infinite;
            animation: scroll-horizontal 10s linear infinite;
}

@-webkit-keyframes scroll-horizontal {
    to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@keyframes scroll-horizontal {
    to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
.div-shadow {
    box-shadow: 1px 1px 10px #bbb;
    border: 0;
}

.about-text {
    font-family: 'Work Sans';
    display: block;
    overflow: auto;
    text-wrap: balance;
    font-size: 1em;
}

.image {
    height: 400px;
}

.bg-white  {
    background-color: #E6DED3;
}
.service-card {
    width: 330px;
    background-color: white;
    box-shadow: 0px 1px 20px #ddd;
}

.works-page {
    background-color: rgb(250, 250, 250);
}
.custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
  .content {
    background-color: #fff;
    border-radius: 4px;
    position: relative; 
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
.gallery-container {
    display: flex;
    flex-direction: row;
    height: "60vh";
    width: '80vw';
    align-items: center;
    overflow: scroll;
}

.left {
    position: absolute;
    left: 20px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding:-3px;
    border: none;
}

.right {
    position: absolute;
    right: 20px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding:-3px;
    border: none;
}
.blog-text {
    width: 60%;
    margin: auto;
    line-height:2;
    margin-top: 20px;
}
.blog-title {
    position: relative;
    text-align: center;
    background-color: white;
    padding: 1.9rem 1.25rem;
    width: 80%;
    margin: 0 auto;
    margin-top: -200px;
}

.blog-title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    height: calc(50vh);
}

.blog-card {
    max-width: 300px;
    padding: 0;
}

.blog-card a {
    text-decoration: none;
    color: #212529;
}
.blog-card a:hover {
    opacity: 80%;
}

.blog-card-image {
    width: 100%;
    height: 100%;
}

.blog-card-body {
    padding: 0;
}

.blog-page {
    background-color: rgb(250, 250, 250);
    min-height: 70vh;
}
