.gallery-container {
    display: flex;
    flex-direction: row;
    height: "60vh";
    width: '80vw';
    align-items: center;
    overflow: scroll;
}

.left {
    position: absolute;
    left: 20px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding:-3px;
    border: none;
}

.right {
    position: absolute;
    right: 20px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding:-3px;
    border: none;
}