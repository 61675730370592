.div-shadow {
    box-shadow: 1px 1px 10px #bbb;
    border: 0;
}

.about-text {
    font-family: 'Work Sans';
    display: block;
    overflow: auto;
    text-wrap: balance;
    font-size: 1em;
}

.image {
    height: 400px;
}

.bg-white  {
    background-color: #E6DED3;
}