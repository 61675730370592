

  .citi-btn {
    background-color: #86BDC9;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
  }

  .citi-btn:hover {
    color: white;
    background-color: #8FC3CE;
    text-decoration: none;
  }

.portofolio-gallery {
  height: 350px;
  display: flex;
  overflow: scroll;

  & img {
    margin: 10px; 
  }
}